import { Outlet } from "react-router";
import { Link } from "react-router-dom";
import { Grid, Icon, Image } from "semantic-ui-react";
import colors from "../utils/colors";
import logo from '../resources/calendar-192.png';
import { useState, useEffect } from "react";
import withRouter from "../utils/withRouter";
import { useLocation } from 'react-router';
import authCache from "../caches/authCache";
import queryString from 'query-string';

function NavBar(props)
{
	var { 
		authLoaded,
		setSideBar, 
		isAdmin,
		windowWidth,
		pageTabs
	} = props;

	const params = queryString.parse(window.location.search);
	const isRelightPage = window.location.pathname === '/relight' || params?.return === 'relight';

	var pathnameStr = window.location.pathname.replace('/', '');
	pathnameStr = pathnameStr.length === 0 ? 'Calendar' : pathnameStr;

	const pageName = pathnameStr[0]?.toUpperCase() + pathnameStr.substring(1);

	const [pathname, setPathname] = useState(window.location.pathname);

	const location = useLocation();
	useEffect((e) => {
		setPathname(window.location.pathname.replace('/', ''));
	}, [location]);

	const auth = authCache.GetAuth();
	const username = auth?.firstname;
	const condensed  = windowWidth <= (isAdmin ? 1060 : 750);
	
	if (isRelightPage) return (null);

	return (
		<div>
			<div 
				style={{
					width: '100%', 
					backgroundColor: colors.secondary,
					padding: 13,
					color: colors.white,
					fontSize: '1.4em',
				}}
			>

				{!condensed &&
					<Grid columns={2}>
						<Grid.Column width={3}>
							<Link to='/calendar'>
								<div 
									style={{
										letterSpacing: 2,
										display: 'flex',
										color: colors.lightGray,
										fontWeight: '400',
										fontSize: 20,
										cursor: 'pointer',
									}}
								>
									<Image src={logo} style={{width:30, marginTop: -5, marginRight: 5}}/>
									{!condensed && 'scheduly'}
								</div>
							</Link>
						</Grid.Column>
						<Grid.Column width={10}>
							<div style={{display: 'flex', marginLeft: 50, marginTop: -3, marginBottom: -5}}>
								{pageTabs?.map((tab, idx) => {
									if (tab.label === 'Profile') return null;
									return <MenuButton key={idx} text={tab.label} path={tab.route} selected={pathname === tab.route}/>
								})}
							</div>
						</Grid.Column>
						<Grid.Column width={3}>
							<div 
								style={{
									width: '100%',
									display: 'flex',
									justifyContent: 'flex-end', 
									marginTop: -3,
									marginBottom: -5
								}} 
							>
								<MenuButton text={authLoaded && (username ? username : 'Login')} path={username ? `profile?id=${auth?.id}` : 'login'} selected={pathname === `/profile`} icon='user circle' />
							</div>
						</Grid.Column>
					</Grid>
				}

				{condensed && 
					<Grid>
						<Grid.Column width={5}>
							<Link to='/calendar'>
								<div style={{height: 30, width: 30}}>
									<Image src={logo} style={{width:30, marginTop: -5, marginRight: 5}}/>
								</div>
							</Link>
						</Grid.Column>
						<Grid.Column width={6}>
							<div 
								style={{width: '100%', fontSize: 14, textAlign: 'center', marginTop: 2, cursor: 'pointer'}}
								onClick={setSideBar}
							>
								<div style={{justifyContent: 'center', display: 'flex'}}>
									<div>{pageName}</div>
									<Icon name='dropdown' />
								</div>
							</div>
						</Grid.Column>
						<Grid.Column width={5}>
							<Link to={username ? `profile?id=${auth?.id}` : '/login'}>
								<div 
									style={{
										width: '100%',
										display: 'flex',
										justifyContent: 'flex-end', 
										cursor: 'pointer', 
										color: colors.lightGray,
									}} 
								>
									<Icon name='user circle' />
								</div>
							</Link>
						</Grid.Column>
					</Grid>
				}

			</div>

			<Outlet/>

		</div>
	);
}

function MenuButton(props) 
{
	const { text, path, selected, icon } = props;

	const [hovered, setHovered] = useState(false);

	return (
		<Link to={`/${path}`}>
			<div style={{
				display: 'flex',
				cursor: 'pointer',
				marginRight: 5, 
				padding: 7,
				paddingRight: 15, 
				paddingLeft: 15, 
				borderRadius: 20, 
				fontSize: 14,
				color: colors.white,
				backgroundColor: selected || hovered? 'rgba(0, 0, 0, 0.3)' : colors.secondary,
			}}
				onMouseEnter={() => setHovered(true)}
				onMouseLeave={() => setHovered(false)}
			>
				{text}
				{icon && <div style={{marginLeft: 5}}><Icon name={icon}/></div>}
				
			</div>
		</Link>
	)
}


export default withRouter(NavBar);