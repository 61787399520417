import '../App.css';
import 'rc-time-picker/assets/index.css';
import 'react-datepicker/dist/react-datepicker.css';


import { Form, Checkbox, Button, Dropdown, Modal, Message, Icon, Transition, List, Label, Image, Loader, Divider, Menu, Segment, Table } from 'semantic-ui-react'
import React from 'react';

import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { format, addDays, addHours, parseJSON, set, isValid, isAfter, isBefore, getHours, getMinutes, differenceInCalendarDays } from 'date-fns'

import config from '../utils/config';
import withRouter from '../utils/withRouter';
import DateUtils from '../utils/DateUtils';
import DropDownSelector from '../components/DropDownSelector';
import api from '../utils/api';
import EventTemplateUtils from '../utils/EventTemplateUtils';
import UserDialog from '../components/UserDialog';
import MyButton from '../components/MyButton';
import AddressInput from '../components/AddressInput';
import { useSearchParams } from 'react-router-dom';
import queryString from 'query-string';
import utils from '../utils/utils';
import memberCache from '../caches/memberCache';
import templatesCache from '../caches/templatesCache';
import Page from '../components/Page';
import colors from '../utils/colors';
import { isDesktop, isMobile } from 'react-device-detect';
import { EventVisibilityOptions } from '../utils/permUtils';
import NotificationEditorPopup from '../popups/NotificationEditorPopup';
import TeamEditorPopup from '../popups/TeamEditorPopup';
import GroupSelectorPopup from '../popups/GroupSelectorPopup';
import AttendancePopup from '../popups/AttendancePopup';
import groupsCache from '../caches/groupsCache';
import loadable from '@loadable/component';
import SmoothModal from '../popups/components/SmoothModal';
import BlockoutConflictsPopup from '../popups/ConflictsPopup';
import MemberFormPopup from '../popups/MemberFormPopup';
import ReactGA from 'react-ga4';
import MemoInput from '../responsive/MemoInput';
import MemoTextArea from '../responsive/MemoTextArea';
import MemoDropdown from '../responsive/MemoDropdown';
import authCache from '../caches/authCache';

import firebase from '../utils/firebase';
import StatusBar from '../components/StatusBar';
import { LoaderPopup } from '../popups/LoaderPopup';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndContainer } from '../responsive/dnd/DndContainer';
import { AvatarTransition } from '../popups/GroupPopup';
import EmptySegment from '../components/EmptySegment';

const TimePickerAsync = loadable(() => import('rc-time-picker'));
const DatePickerAsync = loadable(() => import('react-datepicker'));
const ChromePickerAsync = loadable(() => import('react-color'));

const EMPTY_ATTENDING_FIELDS = [{ field: 'Attending', key: uuidv4(), members: [], memberIds: [], order: 0 }];

const EMPTY_NOTIF_SUBSCRIBED_FIELD = { field: 'Subscribed', key: 'subscribed', members: [], order: 0 };
const MENU_OPTIONS = [
  {
    name: 'Event',
    icon: 'calendar',
  },
  {
    name: 'Schedule',
    icon: 'list ol',
  },
  {
    name: 'Staff',
    icon: 'id badge',
  },
  {
    name: 'Attendance',
    icon: 'users',
  },
  {
    name: 'Alerts',
    icon: 'bell',
  },
  {
    name: 'Teams',
    icon: 'eye',
  },
  {
    name: 'Settings',
    icon: 'setting',
  }
];

class EventForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      editing: false,
      event: null,
      eventTypeOptions: [],
      eventTypeId: null,
      displayName: '',
      location: null,
      color: { hex: '#ffffff' },
      picture: undefined,
      scheduleFields: [],
      staffFields: [],
      attendingFields: EMPTY_ATTENDING_FIELDS,
      notifMemberFields: [EMPTY_NOTIF_SUBSCRIBED_FIELD],
      loading: false,
      loadingText: null,
      initialLoad: false,
      submitLoading: false,
      deleteLoading: false,
      error: null,
      success: null,
      openAuthForm: false,
      openDeleteAuthForm: false,
      openFieldForm: false,
      selectedFieldsDict: null,
      selectedField: null,
      startDate: new Date(),
      startTime: moment(),
      endDate: addHours(new Date(), 1),
      endTime: (moment().add({ hours: 1 })),
      dropDownVals: {},
      displayColorPicker: false,
      openDeleteDialog: false,
      address: '',
      selectedTemplate: null,
      version: 1,
      memberOptions: [],
      groupOptions: [],
      templateOptions: [],
      templateLoading: false,
      notifyNow: false,
      nowNotif: {
        schedule_included: true,
        staff_included: true,
        attending_included: true,
        send_unnotified: true,
        send_unconfirmed: false,
        send_all: false,
        email_enabled: true,
        sms_enabled: true,
      },
      notifSubscribe: false,
      members: null,
      showMissingInfoModal: false,
      membersWithMissingEmails: [],
      membersWithMissingPhones: [],
      eventVisibility: 1,
      eventOwner: this.props.auth?.id,
      scheduledNotifs: [],
      showNotifModal: false,
      anyNotifsModified: false,
      excludedNotifFields: [],
      excludedNotifMembers: [],
      memberNamesDict: {},
      prevEvents: [],
      nextEvents: [],
      showPrevEvents: true,
      showNextEvents: true,
      showStakeholderModal: false,
      stakeholderGroup: null,
      stakeholderGroups: [],
      showStakeholders: false,
      showGroupModal: false,
      groupModalCallback: {},
      showAttendanceModal: false,
      conflicts: null,
      addMemberRequest: null,
      activeItem: 'Event',
      showHistory: false,
    }

    this.topOfPage = React.createRef();
    this.submitBtn = React.createRef();
    this.hiddenFileInput = React.createRef();
  }

  componentDidMount = async () => {
    ReactGA.send('pageview');
    this.topOfPage?.current.scrollIntoView({ behavior: 'smooth' });
    this.reloadPage();
  }

  reloadPage = async () => {
    // this.topOfPage?.current.scrollIntoView({behavior: 'smooth'});

    this.setState({ loading: true, initialLoad: true }, async () => {
      var members = await memberCache.GetMembersDict();
      var memberOptions = await memberCache.GetMemberOptionsArray();
      var groupOptions = await groupsCache.GetGroupOptionsArray();
      var templateOptions = await templatesCache.GetTemplateOptions();
      var memberNamesDict = await memberCache.GetMemberNamesDict();

      this.setState({
        members,
        eventTypeOptions: templateOptions,
        memberOptions,
        groupOptions,
        memberNamesDict,
        loaderOpen: true
      }, () => this.restoreParams());
    })
  }

  restoreParams = async () => {
    const params = queryString.parse(this.props.router.location.search);
    const id = params.id ?? this.props.eventId;
    const activeItem = params.tab ?? 'Event';
    const eventCopy = this.props.router?.location?.state?.eventCopy;

    if (eventCopy) {
      this.copyFromEvent(eventCopy);
      return;
    }

    if (id) {
      await this.getEvent(id);
    }
    else {
      this.onChangeTemplate(null, { value: 'none' })
    }

    await this.handleMenuClick(undefined, { name: activeItem });
  }

  copyFromEvent = async (event) => {
    event.id = null; // clear for new event..

    const clearMemberNotifs = (fields) => {
      fields?.forEach((i) => {
        i?.members?.forEach(m => {
          m.confirmed = undefined;
          m.confirmed_reason = undefined;
          m.confirmed_dt = undefined;
          m.notif_sms_dt = undefined;
          m.notif_email_dt = undefined;
        })
      })
    }

    clearMemberNotifs(event.schedule);
    clearMemberNotifs(event.staff);
    clearMemberNotifs(event.attending);

    await this.setEventState(event);
  }

  setEventState = async (event) => {
    const notifMemberField = EMPTY_NOTIF_SUBSCRIBED_FIELD;
    notifMemberField.members = event.notif?.members?.map((memberId) => {
      return { key: uuidv4(), value: memberId }
    }) ?? [];

    var templateOption = this.state.eventTypeOptions?.find(t => t.key === event.templateId);
    if (templateOption) {
      var template = await templatesCache.GetTemplate(templateOption.key);
    }

    event.schedule.forEach(field => {
      if (field.timestamp) {
        field.timestamp = moment(new Date())
        	.set('hour', getHours(field.timestamp))
          .set('minute', getMinutes(field.timestamp))
      }
    });

    var scheduledNotifs = event.scheduled_notifs.sort((a, b) => {
      return a.notify_date < b.notify_date;
    });

    this.setState(
      {
        selectedTemplate: template,
        editing: event.id,
        eventId: event.id,
        eventTypeId: event.templateId,
        displayName: event.name,
        location: event.location,
        color: { hex: event.color },
        picture: isDesktop ? utils.getLargePicture(event.picture) : utils.getSmallPicture(event.picture),
        startDate: event.start,
        endDate: event.end,
        startTime: DateUtils.GetMoment(event.start),
        endTime: DateUtils.GetMoment(event.end),
        event: event,
        scheduleFields: event.schedule,
        staffFields: event.staff,
        attendingFields: event.attending.length === 0 ? EMPTY_ATTENDING_FIELDS : event.attending,
        notes: event.notes,
        autoNotifyDate: event.notify_date,
        autoNotifyTime: event.notify_date != null ? moment(event.notify_date) : moment(),
        autoNotify: event.notify_date != null,
        loading: false,
        initialLoad: false,
        version: event.version,
        notifSms: event.notif?.sms,
        notifEmail: event.notif?.email,
        notifUnnotified: event.notif?.unnotified,
        notifUnconfirmed: event.notif?.unconfirmed,
        notifAll: event.notif?.all,
        notifSubscribe: notifMemberField.members.length > 0,
        notifMemberFields: [notifMemberField],
        eventOwner: event.owner,
        eventVisibility: event.vis,
        scheduledNotifs: scheduledNotifs,
        stakeholderGroups: event.stakeholderGroups?.map(g => ({ ...g, key: uuidv4() })) ?? []
      },
      () => this.getPrevEvents());

  }

  getPrevEvents = () => {
    // depends on startDate being set..
    var name = this.state.displayName;
    var prevEnd = addDays(this.state.startDate, -1); // do not include curr event..
    var prevStart = addDays(prevEnd, -365);

    api.searchEvents(prevStart, prevEnd, name, 'desc', undefined, 4)
      .then((events) => {
        this.setState({ prevEvents: events });
      }).catch((err) => {
        this.setState({ error: err });
      });

    var nextStart = addDays(this.state.startDate, 1); // do not include curr event..
    var nextEnd = addDays(nextStart, 365);

    api.searchEvents(nextStart, nextEnd, name, 'asc', undefined, 4)
      .then((events) => {
        // const sortedEvents = events.sort((a, b) => {
        //   return a.start < b.start;
        // });
        this.setState({ nextEvents: events });
      }).catch((err) => {
        this.setState({ error: err });
      });
  }

  submit = (overrideMissingContactInfo = false, ignoreConflicts = false, showStatus = true) => {
    return new Promise((resolve, reject) => {
      var {
        editing,
        startDate,
        startTime,
        endDate,
        endTime,
        autoNotifyDate,
        autoNotifyTime,
        notifSms,
        notifEmail,
      } = this.state;

      startDate = set(startDate, { hours: startTime.hours(), minutes: startTime.minutes(), seconds: 0 });
      endDate = set(endDate, { hours: endTime.hours(), minutes: endTime.minutes(), seconds: 0 });

      if (autoNotifyDate && autoNotifyTime) {
        autoNotifyDate = set(autoNotifyDate, { hours: autoNotifyTime.hours(), minutes: autoNotifyTime.minutes(), seconds: 0 });
      }

      var event =
      {
        id: editing ? this.state.event?.id : null,
        templateId: this.state.eventTypeId,
        name: this.state.displayName,
        owner: this.state.eventOwner,
        color: this.state.color?.hex,
        location: this.state.location,
        start: startDate,
        end: endDate,
        schedule: [],
        staff: [],
        attending: [],
        notes: this.state.notes,
        version: this.state.version,
        vis: this.state.eventVisibility,
        scheduled_notifs: this.state.scheduledNotifs,
        stakeholderGroups: this.state.stakeholderGroups
      };

      if (this.state.picture) {
        // typically done async on upload, but reason we upload picture 
        // for event is because we may want to directly save template picture..
        event.picture = this.state.picture;
      }

      if (this.state.notifyNow)
        event.triggered_notif = this.state.nowNotif;

      var membersWithMissingPhones = [];
      var membersWithMissingEmails = [];

      const parseFields = (fields) => {
        const allFields = [];
        for (const field of fields) {
          var members = field?.members?.map(mField => {
            const record = { ...mField };
            if (notifSms && !mField?.phone) membersWithMissingPhones.push(mField.value);
            if (notifEmail && !mField?.email) membersWithMissingEmails.push(mField.value);
            return record;
          })
            .filter(entry => entry.id !== null);

          var fieldTimestamp = null;
          if (moment.isMoment(field.timestamp)) fieldTimestamp = field.timestamp.toDate();
          if (isValid(field.timestamp)) fieldTimestamp = set(startDate, { hours: getHours(field.timestamp), minutes: getMinutes(field.timestamp), seconds: 0 });

          allFields.push({
            field: field.field,
            key: field.key,
            members: [...new Set(members)],
            notes: field.notes,
            declinedMembers: field.declinedMembers,
            syncedMemberIds: field.syncedMemberIds,
            order: allFields.length,
            timestamp: fieldTimestamp,
            groupIds: [...new Set(field.groupIds)],
          });
        }

        return allFields;
      }

      event.schedule = parseFields(this.state.scheduleFields);
      event.staff = parseFields(this.state.staffFields);
      event.attending = parseFields(this.state.attendingFields);

      if (this.state.notifSubscribe) {
        var members = this.state.notifMemberFields[0].members
          .map(mField => {
            var record = { id: mField.value, ...mField.member };
            var memberRecord = this.state.members[mField.value];
            // subscribed users only get email updates..
            if (!memberRecord?.email) {
              membersWithMissingEmails.push(mField.value);
            }
            return record;
          })
          .filter(entry => entry.id !== null);

        event.notif.members = [...new Set(members.map(m => m.id))];
      }

      if (!overrideMissingContactInfo && ((notifSms && membersWithMissingPhones.length > 0) || (notifEmail && membersWithMissingEmails.length > 0))) {
        this.setState({
          showMissingInfoModal: true,
          membersWithMissingPhones,
          membersWithMissingEmails
        });
        resolve();
        return;
      }

      const method = editing ? 'PUT' : 'POST';
      console.log(event);

      this.setState({ error: null, success: null, submitLoading: true, schedule: null, loading: true }, async () => {
        fetch(`${config.endpoint}/events${ignoreConflicts ? '?ignoreConflicts=true' : ''}`, {
          crossDomain: true,
          method: method,
          headers: await api.getRequestHeaders(),
          body: JSON.stringify(event)
        })
          .then(async res => {
            if (res.ok) {
              ReactGA.event({ category: 'EventForm', action: editing ? 'update event' : 'create event' });

              var postedEvent = await res.json();
              config.debug && console.debug(`${method} event`, postedEvent);

              this.setState({
                success: showStatus ? `${method === 'POST' ? 'Created' : 'Updated'} ${postedEvent.name}!` : null,
                submitLoading: false,
                eventId: postedEvent.id,
                version: postedEvent?.version,
                anyNotifsModified: false
              }, () => {
                utils.updateSearchParams(this.props, [{ key: 'id', value: postedEvent?.id }]);
                resolve();
              });

              this.topOfPage?.current.scrollIntoView({ behavior: 'smooth' });

              await this.reloadPage();
            }
            else {
              // Conflict error..
              if (res.status === 409) {
                var data = await res.json();
                // group by memberId
                const conflicts = data.reduce((groups, blockout) => {
                  const group = groups[blockout.memberId] ?? [];
                  // restore fields..
                  utils.restoreBlockout(blockout)
                  group.push(blockout);
                  return { ...groups, [blockout.memberId]: group };
                }, {});

                this.setState({
                  error: `Could not ${editing ? 'update' : 'create'} event because of blockout conflicts`,
                  conflicts,
                  submitLoading: false
                });

                reject();
              }
              else {
                var text = await res.text();
                this.setState({ error: `${res.status} ${res.statusText} - ${text}`, submitLoading: false });
                resolve();
              }
            }

          })
          .catch(error => {
            this.setState({ error, submitLoading: false });
            console.log(error);
            reject(error);
          });
      });
    });
  }

  checkBoxChanged = (field) => {
    this.setState({ [field]: !this.state[field] });
  }

  onChangeMemberDropdown = async (fieldDict, fieldKey, memberIds) => {
    const field = this.state[fieldDict].find(f => f.key === fieldKey);

    const members = await Promise.all(memberIds.map(async id => {
      var existing = field.members?.find(m => m.id === id);
      if (existing) return existing;

      var member = await memberCache.GetSingleMember(id);
      return { value: id, id: id, fullname: member?.fullname };
    }));

    this.setState(prevState => ({
      [fieldDict]: prevState[fieldDict].map(
        f => f.key === fieldKey
          ? {
            ...f,
            memberIds, // memberIds if for DropDownSelector value..
            members // members if for DropDownSelector confirmed / notified state..
          }
          : f,
      )
    }));

    ReactGA.event({ category: 'EventForm', action: `update members in ${fieldDict}` });
  }

  onChangeGroupDropdown = (fieldDict, fieldKey, groups) => {
    var field = this.state[fieldDict].find(f => f.key === fieldKey);
    var updatedGroups = groups.map(group => {
      var existing = field?.groups?.find(m => m.value === group.id)
      if (existing) return existing;
      return { value: group.id, id: group.id, group };
    })

    this.setState(prevState => ({
      [fieldDict]: prevState[fieldDict].map(
        f => f.key === fieldKey
          ? {
            ...f,
            groups: updatedGroups,
            groupIds: updatedGroups.map(g => g.id)
          }
          : f,
      )
    }));

    groups?.forEach(group => {
      group.memberIds?.forEach(id => this.onAddUserEntry(fieldDict, fieldKey, id))
    });

    ReactGA.event({ category: 'EventForm', action: `update group in ${fieldDict}` });
  }

  onChangeMetadata = (fieldsDict, fieldKey, data) => {
    this.setState(prevState => ({
      [fieldsDict]: prevState[fieldsDict].map(
        f => f.key === fieldKey
          ? data
          : f
      ),
    }));

    data?.groupIds?.forEach(async (groupId) => {
      var group = await groupsCache.GetGroup(groupId);
      group.memberIds?.forEach(id => this.onAddUserEntry(fieldsDict, fieldKey, id))
    });

    ReactGA.event({ category: 'EventForm', action: `update metadata in ${fieldsDict}` });
  }

  onAddUserEntry = (fieldsDict, fieldKey, memberId = null) => {
    this.setState(prevState => ({
      [fieldsDict]: prevState[fieldsDict].map(
        f => f.key === fieldKey
          ? {
            ...f,
            memberIds: f.memberIds?.some(mid => mid === memberId)  // members should be unique..
              ? f.memberIds
              : [...f.memberIds, memberId],
            members: f.members?.some(m => m?.id === memberId)  // members should be unique..
              ? f.members
              : [...f.members, { id: memberId }]
          }
          : f
      )
    }));

    ReactGA.event({ category: 'EventForm', action: `add ${fieldsDict} member` });
  }

  buildEventFromTemplate = async (templateId) => {
    const event = await api.buildEvent(templateId, this.state.startDate)
      .catch(error => {
        this.setState({ error });
      });

    console.log('built new event', event);
    await this.setEventState(event);
    this.setState({ templateLoading: false });
  }

  onChangeTemplate = async (_, data) => {
    this.setState({ templateLoading: true, loading: true }, async () => {
      const template = await templatesCache.GetTemplate(data.value);
     
      if (template.id !== 'none') {
        await this.buildEventFromTemplate(template.id, this.state.startDate);
        return;
      }

      const nextStart = EventTemplateUtils.GetNextDate(template.dayOfWeek, new Date());

      const restoreFields = async (fields) => {
        await Promise.all(fields.map(async (field) => {
          if (field.timestamp) {
            const dateObj = new Date(parseJSON(field.timestamp));
            if (isValid(dateObj)) field.timestamp = moment(dateObj);
          }

          if (field.members?.length > 0) {
            field.memberIds = field.members.map(m => m.id);
          }

          // restore group members
          if (field?.groupIds?.length > 0) {
            field.memberIds = [];

            await Promise.all(field?.groupIds?.map(async (groupId) => {
              const group = await groupsCache.GetGroup(groupId);
              group?.memberIds?.forEach((memberId) => {
                if (field.members.some(m => m.id === memberId)) return;
                field.members.push({ id: memberId });
                field.memberIds.push(memberId);
              });
            }));
          }
        }));
      }

      await restoreFields(template?.schedule);
      await restoreFields(template?.staff);
      await restoreFields(template?.attending);

      template.scheduled_notifs?.forEach((n) => {
        n.id = uuidv4(); //need ids to distinguish changes from each other.. 
        n.notify_time = moment(n.tempTimestamp);
        n.notify_date = addDays(nextStart, -1 * n.tempDaysBefore);
        // adjust date by time..
        n.notify_date = set(n.notify_date, { hours: n.notify_time.hours(), minutes: n.notify_time.minutes(), seconds: 0 });
      });

      ReactGA.event({ category: 'EventForm', action: 'update template' });

      this.setState({
        templateLoading: false,
        scheduledNotifs: template?.scheduled_notifs ?? [],
        stakeholderGroups: template.stakeholderGroups?.map(g => ({ ...g, key: uuidv4() })) ?? [],
        selectedTemplate: template,
        scheduleFields: template.schedule,
        staffFields: template.staff,
        attendingFields: template.attending.length === 0 ? EMPTY_ATTENDING_FIELDS : template.attending,
        eventTypeId: data.value,
        displayName: template.eventName ?? '',
        location: template.location,
        color: { hex: template.color },
        picture: template.picture,
        autoNotify: template.autoNotify,
        startTime: template.id === "none" ? moment().add({ hours: 1 }).set({ minutes: 0, seconds: 0 }) : moment(template.startTimestamp),
        endTime: template.id === "none" ? moment().add({ hours: 2 }).set({ minutes: 0, seconds: 0 }) : moment(template.endTimestamp),
        loading: false,
        eventVisibility: template.vis,
        notes: template.notes ?? '',
        initialLoad: false,
      }, () => {
        this.handleDateChange('start', nextStart, false);
        this.handleDateChange('end', nextStart);
      });
    });
  }

  handleDateChange = (field, newDate, handleNotifs = true) => {
    if (field === 'start') {

      const newStart = new Date(newDate);
      if (this.state.startDate === newStart) {
        console.log('existing early start date is same');
        return;
      }

      if (isAfter(newStart, this.state.endDate)) {
        this.setState({ endDate: newStart });
      }

      if (this.state.scheduledNotifs && handleNotifs) {
        // get diff between prev startDate and adjust to new startDate..
        var daysDiff = differenceInCalendarDays(newStart, this.state.startDate);
        this.setState(prevState => ({
          scheduledNotifs: prevState.scheduledNotifs.map(n => {
            n.notify_date = addDays(n.notify_date, daysDiff);
            return n;
          })
        }))
      }

      this.setState({ startDate: newStart }, () => {
        if (this.state.eventTypeId !== 'none' && !this.state.editing) {
          this.buildEventFromTemplate(this.state.eventTypeId, newStart);
          return;
        }
       
        this.getPrevEvents();
      });
    }

    if (field === 'end') {
      var endDate = new Date(newDate);
      this.setState({ endDate });

      if (isBefore(endDate, this.state.startDate)) {
        this.handleDateChange('start', endDate);
      }
    }

    if (field === 'notify') {
      this.setState({ autoNotifyDate: newDate });
    }
  }

  addField = (fieldsDict, fieldKey = null) => {
    var callerIndex = this.state[fieldsDict].findIndex(i => i.key === fieldKey);
    var newField = {
      field: `New Field`,
      key: uuidv4(),
      memberIds: [],
      members: [],
    };

    var callerField = this.state[fieldsDict][callerIndex];
    if (callerField?.timestamp !== undefined && callerField?.timestamp !== null) {
      var prevTimestamp = moment(callerField?.timestamp);
      newField.timestamp = prevTimestamp.set({ minutes: prevTimestamp.minutes() + 5 });
    }

    var fields = this.state[fieldsDict];
    fields.splice(callerIndex + 1, 0, newField);
    this.setState({ [fieldsDict]: fields });

    this.setState({ [`field_visible_${newField.key}`]: false }, () => {
      setTimeout(() => this.setState({ [`field_visible_${newField.key}`]: true }), 2000)
    });

    ReactGA.event({ category: 'EventForm', action: `add field in ${fieldsDict}` });
  }

  removeField = (fieldsDict, fieldKey) => {
    this.setState(prevState => ({
      [fieldsDict]: prevState[fieldsDict].filter(f => f.key !== fieldKey)
    }));

    ReactGA.event({ category: 'EventForm', action: `delete field in ${fieldsDict}` });
  }

  tryDelete = () => {
    this.setState({ openDeleteAuthForm: true });
  }

  deleteEvent = () => {
    this.setState({ deleteLoading: true, error: null }, async () => {
      fetch(`${config.endpoint}/events`,
        {
          method: 'DELETE',
          headers: await api.getRequestHeaders(),
          body: JSON.stringify({ id: this.state.event.id }),
        })
        .then(async res => {
          if (res.ok) {
            ReactGA.event({ category: 'EventForm', action: 'delete event' });

            config.debug && console.debug(`DELETE event`);
            this.setState({ deleteLoading: false }, () => this.props.router.navigate('/'));
          }
          else {
            var text = await res.text();
            this.setState({ error: `${res.status} ${res.statusText} - ${text}`, deleteLoading: false });
          }
        })
        .catch(error => {
          this.setState({ error: 'Failed to connect to server', deleteLoading: false });
          console.log(error);
        });
    });
  }

  onChangeTime = (type, time) => {
    if (time === null)
      return;
    if (type === 'start')
      this.setState({ startTime: time });
    if (type === 'end')
      this.setState({ endTime: time });
    if (type === 'notify') {
      time.set({ 'minutes': 0, 'seconds': 0 });
      this.setState({ autoNotifyTime: time });
    }
  }

  onChangeFieldTimestamp = (fieldsDict, key, value) => {
    this.setState(prevState => ({
      [fieldsDict]: prevState[fieldsDict].map(
        f => f.key === key
          ? { ...f, timestamp: value }
          : f
      )
    }), () => {
      // re-order fields on timestamp
      var fields = [...this.state[fieldsDict]];
      var sortedFields = fields.sort((a, b) => {
        var aTime = (a.timestamp !== null && a.timestamp !== undefined ? isValid(a.timestamp) ? Math.floor(a.timestamp.getTime() / 1000) : a.timestamp.unix() : 10000000000);
        var bTime = (b.timestamp !== null && b.timestamp !== undefined ? isValid(b.timestamp) ? (b.timestamp.getTime() / 1000) : b.timestamp.unix() : 10000000000);
        return aTime - bTime;
      });
      this.setState({ [fieldsDict]: sortedFields });
    });

    ReactGA.event({ category: 'EventForm', action: `update field timestamp` });
  }

  getEvent = (id, loading = true) => {
    return new Promise((resolve, reject) => {
      this.setState({ loading, error: null }, async () => {
        api.getEvent(id, null)
          .then(async event => {
            await this.setEventState(event);
            resolve();
          })
          .catch(error => {
            this.setState({ error: 'Failed to get schedule', loading: false });
            console.log(error);
            resolve();
          });
      });
    });
   
  }

  onAddMemberOption = (fieldDict, fieldKey, fullname, selectedValues) => {
    this.setState({ addMemberRequest: { fieldDict, fieldKey, selectedValues, fullname } });
    ReactGA.event({ category: 'EventForm', action: `create member option` });
  }

  getDropdownMenuOptions = (fieldsDict, fieldKey, field, prevTimestamp = null, canAddTimestamp = false, showAddField = false, showRemove = false) => {
    var timestamp = field?.timestamp ? moment(field.timestamp) : null;

    return (
      <Dropdown
        style={{ color: colors.darkGray }}
        direction='left'
        icon='ellipsis vertical'
      >
        <Dropdown.Menu>

          <Dropdown.Item
            text='Add from Group'
            onClick={() => this.setState({ showGroupModal: true, groupModalCallback: { fieldsDict, fieldKey } })}
          />

          {showAddField &&
            <Dropdown.Item
              text='Add Field'
              onClick={() => this.addField(fieldsDict, fieldKey)}
            />
          }

          {canAddTimestamp && timestamp === null &&
            <Dropdown.Item
              text='Add Timestamp'
              onClick={() => {
                var timestamp = moment();

                if (prevTimestamp)
                  timestamp = prevTimestamp.set({ minutes: prevTimestamp.minutes() + 5, seconds: 0 });

                this.onChangeFieldTimestamp(fieldsDict, fieldKey, timestamp);

                ReactGA.event({ category: 'EventForm', action: `add field timestamp` });
              }
              } />
          }

          {canAddTimestamp && timestamp !== null &&
            <Dropdown.Item
              text='Delete Timestamp'
              onClick={() => {
                this.onChangeFieldTimestamp(fieldsDict, fieldKey, null);
                ReactGA.event({ category: 'EventForm', action: `delete field timestamp` });
              }}
            />
          }

          {showRemove && <Dropdown.Divider />}
          {showRemove &&
            <Dropdown.Item
              text='Delete Field'
              onClick={() => this.removeField(fieldsDict, fieldKey)}
            />
          }

        </Dropdown.Menu>
      </Dropdown>
    );
  }

  addScheduledNotif = () => {
    var newNotif =
    {
      id: uuidv4(),
      notify_date: new Date(),
      notify_time: moment(new Date()).set({ minutes: 0, seconds: 0 }),
      sms_enabled: true,
      email_enabled: true,
      send_unnotified: true,
      schedule_included: true,
      staff_included: true,
      attending_included: true,
      excluded_fields: [],
      excluded_members: [],
    }

    this.setState(prevState => ({
      scheduledNotifs: [...prevState.scheduledNotifs, newNotif],
      anyNotifsModified: true,
      selectedNotif: newNotif,
      showNotifModal: true,
    }));

    ReactGA.event({ category: 'EventForm', action: 'add notification' });
  }

  removeScheduledNotif = (id) => {
    this.setState(prevState => ({
      scheduledNotifs: prevState.scheduledNotifs.filter(n => n.id !== id),
      showNotifModal: false,
      anyNotifsModified: true
    }));

    ReactGA.event({ category: 'EventForm', action: 'delete notification' });
  }

  updateScheduledNotif = () => {
    var scheduledNotif = this.state.selectedNotif;

    scheduledNotif.excluded_fields = this.state.excludedNotifFields;
    scheduledNotif.excluded_members = this.state.excludedNotifMembers;

    if (scheduledNotif.notify_date && scheduledNotif.notify_date) {
      scheduledNotif.notify_date = set(scheduledNotif.notify_date, { hours: scheduledNotif.notify_time.hours(), minutes: scheduledNotif.notify_time.minutes(), seconds: 0 });
    }
    if (this.state.editingNotifNow) {
      this.setState({
        nowNotif: scheduledNotif,
        showNotifModal: false,
        editingNotifNow: false
      });
    }
    else {
      // holds obj in state obj
      this.setState(prevState => ({
        scheduledNotifs: prevState.scheduledNotifs.map(n => {
          if (n.id === scheduledNotif.id) return scheduledNotif;
          return n;
        }),
        showNotifModal: false,
        anyNotifsModified: true
      }));
    }

    ReactGA.event({ category: 'EventForm', action: 'update notification' });
  }

  getAllFields = () => {
    var allFields = [];
    this.state.scheduleFields?.forEach(f => allFields.push({ ...f, isSchedule: true }));
    this.state.staffFields?.forEach(f => allFields.push({ ...f, isStaff: true }));
    this.state.attendingFields?.forEach(f => allFields.push({ ...f, isAttending: true }));
    return allFields;
  }

  getAllMembers = () => {
    var unqiueMembers = new Set();
    this.state.scheduleFields?.forEach(f => f.members?.forEach(m => unqiueMembers.add(m.value)));
    this.state.staffFields?.forEach(f => f.members?.forEach(m => unqiueMembers.add(m.value)));
    this.state.attendingFields?.forEach(f => f.members?.forEach(m => unqiueMembers.add(m.value)));
    var allMembers = Array.from(unqiueMembers).filter(m => m);
    return allMembers;
  }

  openScheduledNotif = (n) => {
    this.setState({
      excludedNotifMembers: n.excluded_members,
      excludedNotifFields: n.excluded_fields,
      selectedNotif: n,
      notifFilterFields: n.excluded_fields?.length > 0 ?? false,
      notifFilterMembers: n.excluded_members?.length > 0 ?? false,
      showNotifModal: true
    })
  }

  getCrossEventsMappedFields = (field) => {
    const { memberNamesDict } = this.state;

    const getFieldNotes = (event, f, prevEvents) => {
      var dateStr = isValid(event.start) ? format(event.start, 'MM/dd ') : 'n/a';
      var values = [];

      var names = f.members.map(m => memberNamesDict[m.id]).map(n => {
        var first = n.split(' ')[0];
        var last = n.split(' ')[1];
        return `${first} ${last[0]}.`
      });
      values.push(names.join(' + '));
      if (f.notes) values.push(f.notes);

      return {
        eventId: event.id,
        key: dateStr,
        values,
        previous: prevEvents,
        future: !prevEvents
      };
    }

    var allData = [];
    allData = allData.concat(this.getMappedFieldsData(field, this.state.prevEvents, getFieldNotes, true));
    allData = allData.concat(this.getMappedMembersCurrent(field));
    allData = allData.concat(this.getMappedFieldsData(field, this.state.nextEvents, getFieldNotes, false));
    return allData;
  }

  getMappedMembersCurrent = (field) => {
    const { memberNamesDict } = this.state;

    var dateStr = isValid(this.state.startDate) ? format(this.state.startDate, 'MM/dd ') : 'Invalid Date - ';
    var values = [];

    var names = field.members.map(m => memberNamesDict[m.id]).map(n => {
      var first = n?.split(' ')[0];
      var last = n?.split(' ')[1];
      return `${first} ${last?.length > 0 ? last[0] : ''}.`
    });
    values.push(names.join(' + '));
    if (field.notes) values.push(field.notes);

    return [{ key: dateStr, values, current: true }];
  }

  getMappedFieldsData = (field, events, getFieldNotes, prevEvents) => {
    var description = [];
    events.forEach((event) => {
      var fieldType = '';
      if (field.isScheduleField) {
        fieldType = 'schedule';
      }
      else if (field.isStaffField) {
        fieldType = 'staff';
      }
      else if (field.isAttendingField) {
        fieldType = 'attending';
      } else {
        return;
      }

      var matchedField = event[fieldType]?.find((f) => f.crossKey === field.crossKey);
      if (matchedField) {
        var note = getFieldNotes(event, matchedField, prevEvents);
        if (note) description.push(note);
      }
    });

    return description;
  }

  onChangeDisplayName = (_, data) => this.setState({ displayName: data.value });

  onChangeNotes = (event) => this.setState({ notes: event.target.value });

  onChangeEventOwner = (_, data) => {
    this.setState({ eventOwner: data.value });
    ReactGA.event({ category: 'EventForm', action: 'update owner' });
  }

  onChangeVisibility = (_, data) => {
    this.setState({ eventVisibility: data.value });
    ReactGA.event({ category: 'EventForm', action: 'update visibility' });
  }

  handleChangeImg = (e) => {
    if (e.target.files.length) {
      const previewUrl = URL.createObjectURL(e.target.files[0]);

      this.setState({
        image: {
          preview: previewUrl,
          raw: e.target.files[0],
          uploadingImg: true
        },
      }, async () => {
        if (!this.state.editing) {
          // event must exist before you can upload a picture..
          await this.submit(false, false, false);
        }
        this.uploadFile();
      });
    }
  };

  uploadFile = () => {
    const { image, eventId } = this.state;
    this.setState({ uploadingImg: true }, async () => {
      const auth = authCache.GetAuth();

      if (image?.raw && eventId) {
        try {
          // Upload raw img to this dir.. cloud function will handle compression and setting picture to group obj..
          var ref = firebase.storage().ref(`events/raw/${auth?.uid}/${eventId}`);
          var res = await ref.put(this.state.image?.raw);

          if (res.state === 'success') {
            // reset picture state because we don't want to send picture from event template
            // if previously selected. We want picture to be populated async by server..
            this.setState({ uploadingImg: false, picture: null });
          }
          else {
            console.error('Failed to upload img', res);
          }

          ReactGA.event({ category: 'EventForm', action: 'Upload Event Picture' });
        }
        catch (error) {
          this.setState({ uploadingImg: false, error: error.toString(), image: null })
          console.error(error);
        }
      }
    });
  };

  handleUploadBtnClick = (e) => {
    this.hiddenFileInput.current.click();
  };

  deletePicture = () => {
    const { event, picture } = this.state;
    if (!event?.id || !picture) return;

    this.setState({ deletePictureLoading: true }, async () => {
      try {
        await api.deletePicture(event.id, 'events');
        this.setState({ picture: undefined });
      }
      catch (ex) {
        this.setState({ error: ex });
      }
      this.setState({ deletePictureLoading: false });
    });
  }

  handleMenuClick = (e, { name }) => {
    this.setState({ activeItem: name }, () => {
      utils.updateSearchParams(this.props, [{ key: 'tab', value: name }]);
    });
  }

  render() {
    const {
      loadingText,
      initialLoad,
      editing,
      submitLoading,
      deleteLoading,
      eventTypeId,
      eventTypeOptions,
      scheduleFields,
      staffFields,
      attendingFields,
      error,
      success,
      templateLoading,
      showMissingInfoModal,
      eventOwner,
      memberOptions,
      scheduledNotifs,
      selectedNotif,
      showStakeholderModal,
      stakeholderGroup,
      showGroupModal,
      groupModalCallback,
      showAttendanceModal,
      conflicts,
      addMemberRequest,
      image,
      picture,
      uploadingImg,
      deletePictureLoading,
      activeItem
    } = this.state;

    // const { windowWidth } = this.props;
    // const displayTitle = windowWidth <= 767 || isMobile;
    const displayImg = (image?.preview || picture);
    const addFieldLength = isDesktop ? 16 : 24;
    const hasAttendees = attendingFields?.length > 0 && attendingFields[0].members.length > 0;

    return (
      <div ref={this.topOfPage}>
        <Page
          header={this.state.displayName}
          helmet={this.state.displayName}
          // loading={loading}   
          loadingText={loadingText}
          // error={error}
          success={success}
          windowWidth={this.props.windowWidth}
          mobilePadding={0}
          btnText={'Save Event'}
          btnAction={() => this.submit()}
        >
          <div style={{  }}>
            <StatusBar style={{ marginBottom: 40 }} error={error} />

            <Menu stackable pointing style={{marginTop: -25}}>
              {MENU_OPTIONS?.map(option => {
                return (<Menu.Item
                  name={option.name}
                  active={activeItem === option.name}
                  onClick={this.handleMenuClick}
                  icon={option.icon}
                >
                </Menu.Item>)
              })}
            </Menu>
            
            <Form>
              <Segment style={{ width: '100%', backgroundColor: colors.pageBackground, minHeight: 500, padding: isDesktop ? 40 : 0, paddingTop: isDesktop? 30 : 0 }} compact>
                {activeItem === 'Event' &&
                  <div style={{ margin: isMobile ? 20 : 0 }}>
                    <Table basic stackable style={{ border: 0 }}>
                      <Table.Row>
                        <Table.Cell width={16}>
                          <h4>Event Time</h4>
                          <Divider />
                          <div style={{ display: 'flex', width: '100%' }}>
                            <div style={{ width: 110, paddingTop: 7 }}>
                              Start
                            </div>
                            <DatePickerAsync
                              selected={this.state.startDate}
                              disabled={this.state.eventTypeId == null}
                              onChange={d => {
                                this.handleDateChange('start', d);
                                ReactGA.event({ category: 'EventForm', action: `update start date` });
                              }}
                            />
                            <div style={{ marginLeft: 10, marginTop: 3 }}>
                              <TimePickerAsync
                                showSecond={false}
                                value={this.state.startTime}
                                placeholder='Time'
                                use12Hours={true}
                                disabled={this.state.eventTypeId == null}
                                onChange={e => {
                                  this.onChangeTime('start', e);
                                  ReactGA.event({ category: 'EventForm', action: `update start time` });
                                }} />
                            </div>
                          </div>
                          <div style={{ display: 'flex', width: '100%', paddingTop: 10 }}>
                            <div style={{ width: 110, paddingTop: 7 }}>
                              End
                            </div>
                            <DatePickerAsync
                              selected={this.state.endDate}
                              disabled={this.state.eventTypeId == null}
                              onChange={d => {
                                this.handleDateChange('end', d);
                                ReactGA.event({ category: 'EventForm', action: `update end date` });
                              }}
                            />
                            <div style={{ marginLeft: 10, marginTop: 3 }}>
                              <TimePickerAsync
                                showSecond={false}
                                value={this.state.endTime}
                                placeholder='Time'
                                use12Hours={true}
                                disabled={this.state.eventTypeId == null}
                                onChange={e => {
                                  this.onChangeTime('end', e);
                                  ReactGA.event({ category: 'EventForm', action: `update end time` });
                                }} />
                            </div>
                          </div>
                        </Table.Cell>
                      </Table.Row>
                    </Table>

                    {!editing &&
                      <Table basic stackable style={{ border: 0 }}>
                        <Table.Row>
                          <Table.Cell width={16}>
                            <h4 style={{}}>Template</h4>
                            <Divider />
                            <MemoDropdown
                              value={this.state.eventTypeId}
                              disabled={eventTypeOptions.length === 0 || editing}
                              placeholder='Event Template..'
                              options={eventTypeOptions}
                              loading={templateLoading}
                              onChange={this.onChangeTemplate}
                              search
                              fluid
                              selection
                            />
                          </Table.Cell>
                        </Table.Row>
                      </Table>
                    }
                    <Table basic stackable style={{ marginTop: 0, border: 0 }}>
                      <Table.Row>
                        <Table.Cell width={displayImg ? 8 : 16}>
                          <h4>Description</h4>
                          <Divider />
                          <MemoInput
                            placeholder='Event Name *'
                            value={this.state.displayName}
                            marginTop={10}
                            maxLength={35}
                            disabled={!this.state.eventTypeId}
                            onChange={this.onChangeDisplayName}
                          />
                          <MemoTextArea
                            marginTop={10}
                            rows={4}
                            maxLength={750}
                            placeholder='Add description (Optional)'
                            value={this.state.notes}
                            onChange={this.onChangeNotes}
                          />
                          <Form.Field style={{ marginTop: 15, marginBottom: 10 }}>
                            <div style={isDesktop ? { maxWidth: 170 } : {}}>
                              <label htmlFor="upload-button">
                                {!displayImg &&
                                  <Button fluid onClick={this.handleUploadBtnClick} disabled={!this.state.displayName}>
                                    <Icon name='picture' />
                                    Upload Picture
                                  </Button>
                                }
                              </label>
                              <input
                                ref={this.hiddenFileInput}
                                type="file"
                                id="upload-button"
                                style={{ display: "none" }}
                                onChange={this.handleChangeImg}
                                accept="image/*"
                              />
                              {uploadingImg &&
                                <div style={{ display: 'flex', marginTop: 20 }}>
                                  <Loader inline active size='small' style={{ marginLeft: 15, marginRight: 15 }} />
                                  Uploading Image..
                                </div>
                              }
                            </div>
                          </Form.Field>

                        </Table.Cell>

                        {displayImg &&
                          <Table.Cell width={8}>
                            <Segment style={{marginTop: 10}} >
                              <Image
                                fluid
                                centered
                                style={{ width: '100%', objectFit: 'cover', borderRadius: 5, maxHeight: 430 }}
                                src={image?.preview ?? picture ?? api.DEFAULT_GROUP_PICTURE}
                                disabled={uploadingImg}
                                size='small'
                              />
                              <div style={{ position: 'absolute', bottom: 20, left: 20 }} >
                                <Button disabled={deletePictureLoading} loading={deletePictureLoading} compact onClick={this.deletePicture}>
                                  <Icon name='trash' />
                                  Delete
                                </Button>
                              </div>
                            </Segment>
                          </Table.Cell>
                        }
                      </Table.Row>
                    </Table>

                    <h4>Location</h4>
                    <Divider />
                    <div style={{ marginTop: 0 }}>
                      <div style={{ display: 'flex' }}>
                        <div style={{ paddingRight: 15, paddingTop: 7, minWidth: 60 }}>Address</div>
                        <div style={{ width: '100%' }}>
                          <AddressInput
                            value={this.state.location ?? ''}
                            disabled={!this.state.eventTypeId}
                            onChange={(address) => this.setState({ location: address })}
                            onSelect={(address) => this.setState({ location: address })}
                          />
                        </div>
                      </div>
                      {this.state.location &&
                        <Segment>
                        <iframe
                          title={this.state.location}
                          width='100%'
                          height='350'
                          style={{ border: 0, marginTop: 0 }}
                          loading="lazy"
                          src={`https://www.google.com/maps/embed/v1/search?q=${this.state.location}&key=AIzaSyBjSXgk9L5PlmJdFW4RKqmD05WmEj_SZ_k`}
                        />
                        </Segment>
                      }
                    </div>
                  </div>
                }

                {activeItem === 'Schedule' &&
                  <Table stackable basic style={{ border: 0, paddingTop: isMobile ? 20 : 0 }}>
                    {scheduleFields.length === 0 ?
                      <Table.Row>
                        <EmptySegment
                          title='Schedule Empty'
                          description="Event does not have any items in the schedule."
                          icon='list ol'
                          btnText='Add Schedule'
                          btnAction={() => this.addField('scheduleFields', scheduleFields[scheduleFields.length - 1]?.key)}
                          segment
                        />
                      </Table.Row>
                      :
                      <Table.Row verticalAlign='top'>
                        <Table.Cell width={4} >
                          <Segment style={{ height: '100%' }} color='orange'>
                            <div style={{ fontWeight: 'bold', fontSize: 16, paddingLeft: 10, color: colors.black }}> Schedule Order </div>
                            <Divider />
                            <DndProvider backend={HTML5Backend}>
                              <DndContainer
                                cards={scheduleFields}
                                setCards={(cards) => this.setState({ scheduleFields: cards })}
                                renderCard={(card, idx) => {
                                  return (
                                    <div style={{ borderRadius: 5, padding: 5, paddingLeft: 10, marginTop: -5, backgroundColor: colors.accent, color: colors.white }}>
                                      <Icon name='bars' />
                                      {`${card?.field?.length > addFieldLength ? card?.field?.substring(0, addFieldLength) + '..' : card?.field}`}
                                    </div>
                                  );
                                }}
                              />
                            </DndProvider>
                            <div style={{ padding: 10 }}>
                              <Button
                                color={colors.lightGray}
                                fluid
                                onClick={() => this.addField('scheduleFields', scheduleFields[scheduleFields.length - 1]?.key)}
                              >Add Field</Button>
                            </div>
                          </Segment>

                          <Segment style={{ height: '100%' }}>
                            <div style={{ fontWeight: 'bold', fontSize: 16, paddingLeft: 10, color: colors.black }}> Settings </div>
                            <Divider />
                            <Checkbox label='Field History'
                              defaultChecked={this.state.showHistory}
                              onChange={(e, d) => this.setState({ showHistory: d.checked })}
                            />
                          </Segment>
                        </Table.Cell>

                        <Table.Cell width={isMobile ? 16 : 12} style={{ backgroundColor: colors.pageBackground }}>
                          <Transition.Group
                            as={List}
                            duration={config.fieldTransition.duration}
                            animation={config.fieldTransition.animation}
                          >
                            {scheduleFields.map((i, index) => {
                              return (
                                <List.Item>
                                  <DropDownSelector
                                    index={index}
                                    fieldKey={i.key}
                                    fieldDict='scheduleFields'
                                    fieldData={i}
                                    field={i.field}
                                    notes={i.notes}
                                    value={i.memberIds}
                                    groupIds={i.groupIds}
                                    crossFields={this.state.showHistory ? this.getCrossEventsMappedFields(i): []}
                                    timestamp={i.timestamp}
                                    options={this.state.memberOptions}
                                    groupOptions={this.state.groupOptions}
                                    hideGroupsOnNone
                                    onChangeMetadata={this.onChangeMetadata}
                                    onChangeDropdown={this.onChangeMemberDropdown}
                                    onAddOption={this.onAddMemberOption}
                                    menuOptions={this.getDropdownMenuOptions}
                                    onChangeTimestamp={this.onChangeFieldTimestamp}
                                    menu_AddTimestamp={true}
                                    menu_AddField={true}
                                    menu_RemoveField={true}
                                  />
                                </List.Item>
                              );
                            })}
                          </Transition.Group>
                        </Table.Cell>
                      </Table.Row>
                    }
                    </Table>
                }

                {activeItem === 'Staff' &&
                  <Table stackable basic style={{ border: 0, paddingTop: isMobile ? 20 : 0 }}>
                    {staffFields.length === 0 ?
                      <Table.Row>
                        <EmptySegment
                          title='No Staff Roles'
                          description="Event does not have any staff roles."
                          icon='badge id'
                          btnText='Add Role'
                          btnAction={() => this.addField('staffFields', staffFields[staffFields.length - 1]?.key)}
                          segment
                        />
                      </Table.Row>
                      :
                      <Table.Row verticalAlign='top'>
                        <Table.Cell width={4} >
                          <Segment style={{ height: '100%' }} color='orange'>
                            <div style={{ fontWeight: 'bold', fontSize: 16, paddingLeft: 10, color: colors.black }}> Staff Order </div>
                            <Divider />
                            <DndProvider backend={HTML5Backend}>
                              <DndContainer
                                cards={staffFields}
                                setCards={(cards) => this.setState({ staffFields: cards })}
                                renderCard={(card, idx) => {
                                  return (
                                    <div style={{ borderRadius: 5, padding: 5, paddingLeft: 10, marginTop: -5, backgroundColor: colors.accent, color: colors.white }}>
                                      <Icon name='bars' />
                                      {`${card?.field?.length > addFieldLength ? card?.field?.substring(0, addFieldLength) + '..' : card?.field}`}
                                    </div>
                                  );
                                }}
                              />
                            </DndProvider>
                            <div style={{ padding: 10 }}>
                              <Button
                                color={colors.lightGray}
                                fluid
                                onClick={() => this.addField('staffFields', staffFields[staffFields.length - 1]?.key)}
                              >Add Field</Button>
                            </div>
                          </Segment>
                          <Segment style={{ height: '100%' }}>
                            <div style={{ fontWeight: 'bold', fontSize: 16, paddingLeft: 10, color: colors.black }}> Settings </div>
                            <Divider />
                            <Checkbox label='Field History'
                              defaultChecked={this.state.showHistory}
                              onChange={(e, d) => this.setState({ showHistory: d.checked })}
                            />
                          </Segment>
                        </Table.Cell>

                        <Table.Cell width={isMobile ? 16 : 12} style={{ backgroundColor: colors.pageBackground }}>
                          <div style={{}}>
                            <Transition.Group
                              as={List}
                              duration={config.fieldTransition.duration}
                              animation={config.fieldTransition.animation}
                            >
                              {staffFields.map((i, index) => {
                                return (
                                  <List.Item style={{}}>
                                    <DropDownSelector
                                      index={index}
                                      fieldKey={i.key}
                                      fieldDict='staffFields'
                                      fieldData={i}
                                      field={i.field}
                                      notes={i.notes}
                                      value={i.memberIds}
                                      groupIds={i.groupIds}
                                      crossFields={this.state.showHistory ? this.getCrossEventsMappedFields(i) : []}
                                      options={this.state.memberOptions}
                                      groupOptions={this.state.groupOptions}
                                      hideGroupsOnNone
                                      onChangeMetadata={this.onChangeMetadata}
                                      onChangeDropdown={this.onChangeMemberDropdown}
                                      onAddOption={this.onAddMemberOption}
                                      menuOptions={this.getDropdownMenuOptions}
                                      onChangeTimestamp={this.onChangeFieldTimestamp}
                                      menu_AddField={true}
                                      menu_RemoveField={true}
                                    />
                                  </List.Item>
                                );
                              })}
                            </Transition.Group>
                          </div>
                        </Table.Cell>
                      </Table.Row>
                    }
                    </Table>
                }

                {activeItem === 'Attendance' &&
                  <Table basic style={{ border: 0, paddingTop: isMobile? 20: 0 }}>
                    {(attendingFields?.length > 0 && attendingFields[0].members.length === 0) &&
                      <Table.Row >
                        <Table.Cell width={16}>
                        <EmptySegment
                          title='No Attendance'
                          description="Event does not have anyone attending."
                          icon='users'
                          segment
                          />
                        </Table.Cell>
                      </Table.Row>
                    }
                     
                      {attendingFields?.map((i, index) => {
                        return (
                          <Table.Row>
                            {hasAttendees && 
                              <Table.Cell width={6}>
                                {i.members?.length > 0 &&
                                  <Segment style={{}} color='black'>
                                    <div style={{ fontWeight: 'bold', fontSize: 18, paddingBottom: 15, display: 'flex' }}>
                                      Attending
                                      <Label circular style={{ marginLeft: 7 }}>{i.members?.length ?? 0}</Label>
                                    </div>
                                    <div style={{ display: 'block', padding: 0, marginBottom: 5, marginTop: 5 }}>
                                      {i.members?.map((member, idx) => <AvatarTransition imgSize={40} key={idx} member={member} delay={idx * 5} router={this.props.router} />)}
                                    </div>
                                  </Segment>
                                }
                              </Table.Cell>
                            }
                            <Table.Cell width={hasAttendees ? 10 : 16}>
                              <div style={{ }}>
                                <DropDownSelector
                                  index={0}
                                  fieldKey={i.key}
                                  fieldDict='attendingFields'
                                  fieldData={i}
                                  field={i.field}
                                  notes={i.notes}
                                  value={i.memberIds}
                                  groupIds={i.groupIds}
                                  readonlyField
                                  placeholder='Add Attendees'
                                  hideNotes
                                  // hideField
                                  memberFields={i.members}
                                  options={this.state.memberOptions}
                                  groupOptions={this.state.groupOptions}
                                  hideGroupsOnNone
                                  onChangeMetadata={this.onChangeMetadata}
                                  onChangeDropdown={this.onChangeMemberDropdown}
                                  onAddOption={this.onAddMemberOption}
                                  menuOptions={this.getDropdownMenuOptions}
                                />
                                {hasAttendees &&
                                  <Form.Field>
                                    <MyButton
                                      style={{ marginTop: 20 }}
                                      label='Take Attendance'
                                      onClick={() => {
                                        this.setState({ showAttendanceModal: true });
                                      }}
                                    />
                                  </Form.Field>
                                }
                              </div>
                            </Table.Cell>
                          </Table.Row>
                        );
                      })}
                  </Table>
                }

                {activeItem === 'Alerts' &&
                  <div style={{ padding: isMobile ? 20 : 0 }}>
                    <NotificationEditorPopup
                      open={this.state.showNotifModal}
                      onClose={() => this.setState({ showNotifModal: false })}
                      notifyNow={this.state.editingNotifNow}
                      allFields={this.getAllFields()}
                      allMembers={this.getAllMembers()}
                      change_notify_date={(d) => this.setState(prevState => ({
                        selectedNotif: { ...prevState.selectedNotif, notify_date: d }
                      }))}
                      change_notify_time={(e) => {
                        this.setState(prevState => ({
                          selectedNotif: { ...prevState.selectedNotif, notify_time: e }
                        }))
                      }}
                      memberNames={this.state.memberNamesDict}
                      excludedNotifMembers={this.state.excludedNotifMembers}
                      excludedNotifFields={this.state.excludedNotifFields}
                      selectedNotif={this.state.selectedNotif}
                      change_schedule_included={() => this.setState(prevState => ({
                        selectedNotif: { ...prevState.selectedNotif, schedule_included: !prevState.selectedNotif.schedule_included }
                      })
                      )}
                      change_staff_included={() => this.setState(prevState => ({
                        selectedNotif: { ...prevState.selectedNotif, staff_included: !prevState.selectedNotif.staff_included }
                      })
                      )}
                      change_attending_included={() => this.setState(prevState => ({
                        selectedNotif: { ...prevState.selectedNotif, attending_included: !prevState.selectedNotif.attending_included }
                      })
                      )}
                      change_send_unnotified={() => this.setState(prevState => ({
                        selectedNotif: { ...prevState.selectedNotif, send_unnotified: !prevState.selectedNotif.send_unnotified }
                      })
                      )}
                      change_send_unconfirmed={() => this.setState(prevState => ({
                        selectedNotif: { ...prevState.selectedNotif, send_unconfirmed: !prevState.selectedNotif.send_unconfirmed }
                      })
                      )}
                      change_send_all={() => this.setState(prevState => ({
                        selectedNotif: { ...prevState.selectedNotif, send_all: !prevState.selectedNotif.send_all }
                      })
                      )}
                      change_sms_enabled={() => this.setState(prevState => ({
                        selectedNotif: { ...prevState.selectedNotif, sms_enabled: !prevState.selectedNotif.sms_enabled }
                      })
                      )}
                      change_email_enabled={() => this.setState(prevState => ({
                        selectedNotif: { ...prevState.selectedNotif, email_enabled: !prevState.selectedNotif.email_enabled }
                      })
                      )}
                      add_excluded_fields={(fKey) => {
                        this.setState(prevState => ({
                          excludedNotifFields: [...prevState.excludedNotifFields, fKey]
                        }));
                      }}
                      remove_excluded_fields={(fKey) => {
                        this.setState(prevState => ({
                          excludedNotifFields: prevState.excludedNotifFields?.filter(key => key !== fKey)
                        }));
                      }}
                      add_excluded_member={(mKey) => {
                        this.setState(prevState => ({
                          excludedNotifMembers: [...prevState.excludedNotifMembers, mKey]
                        }));
                      }}
                      remove_excluded_member={(mKey) => {
                        this.setState(prevState => ({
                          excludedNotifMembers: prevState.excludedNotifMembers?.filter(key => key !== mKey)
                        }));
                      }}
                      onRemove={() => this.removeScheduledNotif(selectedNotif.id)}
                      onCancel={() => {
                        if (this.state.editingNotifNow)
                          this.setState({ notifyNow: false, showNotifModal: false })
                        else
                          this.setState({ showNotifModal: false })
                      }}
                      onSave={() => this.updateScheduledNotif()}
                    />

                    {this.state.anyNotifsModified &&
                      <Message color='yellow'>
                        <Message.Header>Unsaved Changes</Message.Header>
                        <p>Save the event to update the scheduled notifications</p>
                      </Message>
                    }

                    {this.state.notifyNow && 
                      <Message
                        icon='warning'
                        header='Attention'
                        content='Alert will be triggered once you save Event'
                        color='yellow'
                      />
                    }

                    <Form.Field style={{ padding: 5 }}>
                      <Checkbox
                        checked={this.state.notifyNow}
                        disabled={eventTypeId === null}
                        label={'Trigger Alert'}
                        onChange={async (e, data) => {
                          this.setState({
                            notifyNow: data.checked,
                            selectedNotif: this.state.nowNotif,
                            showNotifModal: data.checked,
                            editingNotifNow: data.checked,
                          });

                          if (data.checked) {
                            ReactGA.event({ category: 'EventForm', action: 'trigger notification' });
                          }

                          api.logRemote(`triggered manual notification for '${utils.getEventName(this.state.event)}'`, { selectedNotif });
                        }}
                      />
                    </Form.Field>

                    <h4>Scheduled Alerts</h4>
                    <Divider />

                    <Transition.Group
                      as={List}
                      duration={config.fieldTransition.duration}
                      animation={config.fieldTransition.animation}
                    >
                      {scheduledNotifs?.map((n, idx) => {
                        var dateStr = isValid(n.notify_date) ? format(n.notify_date, 'EEEE, MMMM do') : 'Unknown Date';
                        var timeStr = moment(n.notify_time).format('h:mm A')

                        var eventGroups = [];
                        if (n.schedule_included) eventGroups.push('Schedule')
                        if (n.staff_included) eventGroups.push('Staff')
                        if (n.attending_included) eventGroups.push('Attending')

                        var notifGroups = [];
                        if (n.send_all) notifGroups.push('All')
                        if (n.send_unconfirmed) notifGroups.push('Un-confirmed')
                        if (n.send_unnotified) notifGroups.push('Un-notified')

                        var deliveryGroups = [];
                        if (n.sms_enabled) deliveryGroups.push('SMS')
                        if (n.email_enabled) deliveryGroups.push('Email')

                        const expired = isAfter(new Date(), n.notify_date);

                        return (
                          <List.Item key={idx} style={{ padding: 5, marginTop: idx === 0 ? 0 : 10 }}>
                            <Segment attached='top' inverted color={expired ? 'black' : 'black'} >
                              <div style={{ display: '' }}>
                                <div style={{ whiteSpace: 'pre-wrap' }}>
                                  {expired ? 'Sent  ' : 'Scheduled  '}
                                  <Label color={expired ? 'blue' : 'orange'}>{dateStr}</Label>
                                  {'  at  '}
                                  <Label color={expired ? 'blue' : 'orange'}>{timeStr}</Label>
                                </div>
                                <div style={{  position: 'absolute', right: 15, top: 15}}>
                                  <Icon name='pencil' onClick={() => this.openScheduledNotif(n)} />
                                </div>
                              </div>                             
                            </Segment>
                            <Segment attached padded>
                              <div style={{ display: 'flex' }}>
                                <div style={{ display: 'flex', color: n.sms_enabled ? 'green' : 'red' }}>
                                  <Icon name={n.sms_enabled ? 'check circle' : 'remove circle'} />
                                </div>
                                <div style={{ paddingLeft: 5, }}>
                                  SMS
                                </div>
                                <div style={{ display: 'flex', paddingLeft: 25, color: n.email_enabled ? 'green' : 'red' }}>
                                  <Icon name={n.email_enabled ? 'check circle' : 'remove circle'} />
                                </div>
                                <div style={{ paddingLeft: 5, }}>
                                  Email
                                </div>
                              </div>
                            </Segment>
                            <Segment attached >
                              <div style={{ display: 'flex' }}>
                                {/* <Icon name='bell' /> */}
                                <div style={{ paddingLeft: 0, fontWeight: 'bold' }}>
                                  Target:
                                </div>
                                <div style={{ paddingLeft: 5 }}>
                                  {notifGroups.join(', ')}
                                </div>
                              </div>
                            </Segment>
                            <Segment attached='bottom'>
                              <div style={{ display: 'flex' }}>
                                {/* <Icon name='users' /> */}
                                <div style={{ paddingLeft: 0, fontWeight: 'bold' }}>
                                  In Groups:
                                </div>
                                <div style={{ paddingLeft: 5 }}>
                                  {eventGroups.join(', ')}
                                </div>
                              </div>
                            </Segment>
                          </List.Item>
                        );
                      })}
                    </Transition.Group>

                    {scheduledNotifs?.length === 0 ?
                      <EmptySegment
                        title='No Alerts'
                        description="Event does not have any alerts configured."
                        icon='bell slash'
                        btnText='Add Alert'
                        btnAction={() => this.addScheduledNotif()}
                        segment
                      />
                      :
                      <Form.Field>
                        <Button
                          fluid
                          color={colors.lightGray}
                          style={{ marginTop: scheduledNotifs.length === 0 ? 0 : 20 }}
                          onClick={() => this.addScheduledNotif()}
                        >Add Alert</Button>
                      </Form.Field>
                    }
                  </div>
                }

                {activeItem === 'Teams' &&
                  <div style={{ padding: isMobile ? 20 : 0 }}>
                    <Transition.Group
                      as={List}
                      duration={config.fieldTransition.duration}
                      animation={config.fieldTransition.animation}
                    >
                      {this.state.stakeholderGroups?.map((g) => {
                        return (
                          <List.Item>
                            <Button
                              key={g.key}
                              fluid
                              color='instagram'
                              style={{ marginBottom: 10 }}
                              onClick={() => this.setState({ showStakeholderModal: true, stakeholderGroup: g })}
                            >
                              {g.name}
                            </Button>
                          </List.Item>)
                      })}
                    </Transition.Group>
                    {this.state.stakeholderGroups.length === 0 ?
                      <EmptySegment
                        title='No Teams'
                        description="Event does not have teams watching."
                        icon='users'
                        btnText='Add Team'
                        btnAction={() => this.setState({ showStakeholderModal: true, stakeholderGroup: null })}
                        segment
                      /> 
                      :
                      <Form.Field>
                        <MyButton
                          style={{ marginTop: this.state.stakeholderGroups.length === 0 ? 0 : 20 }}
                          label='Add Team'
                          onClick={() => this.setState({ showStakeholderModal: true, stakeholderGroup: null })}
                        />
                      </Form.Field>
                    }
                  </div>
                }

                {activeItem === 'Settings' &&
                  <div style={{ padding: isMobile ? 20 : 0 }}>
                    <div >
                      <h4>Appearance</h4>
                      <Divider />
                      <Button
                        style={{ width: '100%', backgroundColor: this.state.color?.hex, marginTop: 0 }}
                        onClick={() => this.setState({ displayColorPicker: !this.state.displayColorPicker })}
                      >
                        Color ({this.state.color.hex})
                      </Button>
                      {this.state.displayColorPicker ? <div style={{
                        position: 'absolute',
                        zIndex: '2'
                      }}>
                        <div style={{
                          position: 'fixed',
                          top: '0px',
                          right: '0px',
                          bottom: '0px',
                          left: '0px'
                        }} onClick={() => this.setState({ displayColorPicker: false })} />
                        <ChromePickerAsync color={this.state.color} onChange={(color, e) => {
                          this.setState({ color });
                          ReactGA.event({ category: 'EventForm', action: 'update color' });
                        }} />
                      </div> : null}
                    </div>
                    <Form.Field style={{ marginTop: 20 }}>
                      <h4>Privacy</h4>
                      <Divider />
                      <div style={{ display: 'flex', marginTop: 10 }}>
                        <div style={{ paddingRight: 15, paddingTop: 7, minWidth: 60 }}>Owner</div>
                        <MemoDropdown
                          value={eventOwner}
                          placeholder='Event Owner'
                          options={memberOptions}
                          onChange={this.onChangeEventOwner}
                          search
                          selection
                          fluid
                        />
                      </div>
                      <div style={{ display: 'flex', marginTop: 10 }}>
                        <div style={{ paddingRight: 10, paddingTop: 7, minWidth: 60 }}>Privacy</div>
                        <MemoDropdown
                          value={this.state.eventVisibility}
                          placeholder='Visibility'
                          options={EventVisibilityOptions}
                          labeled
                          onChange={this.onChangeVisibility}
                          search
                          selection
                          fluid
                        />
                      </div>
                    </Form.Field>
                    {editing &&
                      <Form.Field style={{ marginTop: 20 }}>
                        <h4>Storage</h4>
                        <Divider />
                        <Form.Field>
                          <Button
                            fluid
                            color='google plus'
                            // negative
                            onClick={() => this.setState({ openDeleteDialog: true })}
                            disabled={deleteLoading || submitLoading || eventTypeId == null}
                          >Delete Event</Button>
                        </Form.Field>
                      </Form.Field>
                    }
                  </div>
                }
              </Segment>
            </Form>

            <LoaderPopup open={initialLoad} title={`Loading Event ..`} />

            <UserDialog
              open={this.state.openDeleteDialog}
              header='Warning'
              body='Delete action cannot be undone. Are you sure you want to continue?'
              submitBtnText='Delete'
              submitBtnNegative
              onClose={() => this.setState({ openDeleteDialog: false })}
              onSubmit={this.deleteEvent}
            />

            <SmoothModal
              open={showMissingInfoModal}
              size='small'
              header='Missing Contact Info'
              onClose={() => this.setState({ showForm: false })}
            >
              <Modal.Content>
                <div style={{ paddingBottom: 20 }}>
                  Warning: Some people will not be notified.
                </div>
                {this.state.membersWithMissingPhones.length > 0 &&
                  <div style={{ color: 'red' }}>
                    SMS will fail to send to
                    <ul>
                      {this.state.membersWithMissingPhones.map(id => {
                        var fullname = this.state.members.find(m => m.id === id)?.fullname;
                        return (<li>{fullname}</li>)
                      })}
                    </ul>
                  </div>
                }
                {this.state.membersWithMissingEmails.length > 0 &&
                  <div style={{ color: 'red' }}>
                    Email will fail to send to
                    <ul>
                      {this.state.membersWithMissingEmails.map(id => {
                        var fullname = this.state.members.find(m => m.id === id)?.fullname;
                        return (<li>{fullname}</li>)
                      })}
                    </ul>
                  </div>
                }
                <div style={{ paddingTop: 10, color: colors.darkGray }}>
                  Please update their contact info.
                </div>
              </Modal.Content>
              <Modal.Actions>
                <Button
                  content='Cancel'
                  onClick={() => this.setState({ showMissingInfoModal: false })}
                />
                <Button
                  negative
                  content="Ignore & Continue"
                  onClick={async (e) => {
                    this.setState({ showMissingInfoModal: false });
                    this.submit(true)
                  }}
                />
              </Modal.Actions>
            </SmoothModal>

            <TeamEditorPopup
              open={showStakeholderModal}
              router={this.props.router}
              eventFields={this.getAllFields()}
              memberOptions={memberOptions}
              selected={stakeholderGroup}
              onClose={() => this.setState({ showStakeholderModal: false })}
              onRemove={(key) => {
                this.setState(prevState => ({
                  stakeholderGroups: prevState.stakeholderGroups.filter(g => g.key !== key),
                  showStakeholderModal: false,
                }));

                ReactGA.event({ category: 'EventForm', action: 'delete team' });
              }}
              onCreate={(g) => {
                this.setState(prevState => ({
                  stakeholderGroups: [...prevState.stakeholderGroups, g],
                  showStakeholderModal: false,
                }));

                ReactGA.event({ category: 'EventForm', action: 'create team' });
              }}
              onUpdate={(g) => {
                this.setState(prevState => ({
                  stakeholderGroups: prevState.stakeholderGroups.map((s) => {
                    if (s.key === g.key) return g;
                    return s;
                  }),
                  showStakeholderModal: false,
                }));

                ReactGA.event({ category: 'EventForm', action: 'update team' });
              }}
            />

            <GroupSelectorPopup
              open={showGroupModal}
              router={this.props.router}
              onClose={() => this.setState({ showGroupModal: false })}
              onSelected={(groups, members) => {
                this.setState({ showGroupModal: false });
                this.onChangeGroupDropdown(groupModalCallback.fieldsDict, groupModalCallback.fieldKey, groups);
              }}
            />

            <MemberFormPopup
              open={addMemberRequest}
              fullname={addMemberRequest?.fullname}
              router={this.props.router}
              onClose={() => this.setState({ addMemberRequest: null })}
              onSave={async (member) => {
                const fieldDict = addMemberRequest?.fieldDict;
                const fieldKey = addMemberRequest?.fieldKey;
                const selectedValues = addMemberRequest?.selectedValues;

                if (!fieldDict || !fieldKey || !selectedValues) return;
                if (member?.id) selectedValues.push(member.id);

                const updatedOptions = await memberCache.GetMemberOptionsArray();
                this.setState({
                  memberOptions: updatedOptions,
                }, () => {
                  this.onChangeMemberDropdown(fieldDict, fieldKey, selectedValues);
                });
              }}
            />

            <AttendancePopup
              open={showAttendanceModal}
              onClose={() => this.setState({ showAttendanceModal: false })}
              field={this.state.attendingFields[0]}
              onSave={(field) => {
                ReactGA.event({ category: 'EventForm', action: 'submit attendance' });
                this.setState({ attendingFields: [field] });
              }}
            />

            <BlockoutConflictsPopup
              open={conflicts !== null}
              onClose={() => this.setState({ conflicts: null })}
              onIgnore={() => {
                this.setState({ conflicts: null }, () => this.submit(true, true));
              }}
              conflicts={conflicts}
            />

            <LoaderPopup open={this.state.submitLoading || this.state.deleteLoading} />
          </div>
        </Page>
      </div>
    );
  }
}


function EventFormHooks(props) {
  // eslint-disable-next-line no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();
  return (<EventForm setSearchParams={setSearchParams} {...props} />)
}

export default withRouter(EventFormHooks);