import React, {  } from "react";
import { Button, Checkbox, Dropdown, Form, Input, Message, Modal } from "semantic-ui-react";
import config from "../utils/config";
import api from "../utils/api";
import colors from "../utils/colors";
import SmoothModal from "./components/SmoothModal";
import { format, parse, isValid, startOfDay, endOfDay } from 'date-fns';
import ReactGA from 'react-ga4';


const FREQUENCY_OPTIONS = [
	{
		key: 'day',
		text: 'Days',
		value: 'day',
	},
	{
		key: 'week',
		text: 'Weeks',
		value: 'week',
	},
	{
		key: 'month',
		text: 'Months',
		value: 'month',
	},
	{
		key: 'year',
		text: 'Years',
		value: 'year',
	}
];

export default class BlockoutPopup extends React.Component 
{
	constructor(props)
	{
		super(props);
		this.state = {
			error: null,
			loading: false,
			editing: false,
			id: null,
			startDateStr: null,
			endDateStr: null,
			startTimeStr: null,
			endTimeStr: null,
			untilDateStr: null,
			allDay: true,
			repeat: false,
			frequency: null,
			frequencyUnit: null,
			reason: null,
		}
	}

	componentDidMount = async () => 
	{
	}

	componentDidUpdate = (prevProps) => 
	{
		// Only trigger when popup is opened..
		if (prevProps.open !== this.props.open && this.props.open !== false)
		{
			ReactGA.event({ category: 'BlockoutPopup', action: 'Open BlockoutPopup'});

			this.setState({
				error: null,
				loading: true,
				editing: false,
				startDateStr: format(new Date(), 'yyyy-MM-dd'),
				endDateStr: format(new Date(), 'yyyy-MM-dd'),
				reason: '',
				allDay: true,
				repeat: false,
				frequency: 1,
				frequencyUnit: 'week'
			}, async () => await this.restoreParams());
		}
	}

	restoreParams = async () => 
	{
		if (this.props.selectedId)
		{
			this.setState({loading: true}, async () => 
			{
				const blockout = await api.getBlockout(this.props.selectedId);
				this.setState({
					error: null,
					id: blockout.id,
					editing: true,
					startDateStr: format(blockout.nextStart, 'yyyy-MM-dd'),
					endDateStr: format(blockout.nextEnd, 'yyyy-MM-dd'),
					startTimeStr: format(blockout.nextStart, 'HH:mm'),
					endTimeStr: format(blockout.nextEnd, 'HH:mm'),
					untilDateStr: isValid(blockout.until) ? format(blockout.until, 'yyyy-MM-dd') : null,
					allDay: blockout.allDay,
					repeat: blockout.repeat,
					frequency: blockout.frequency,
					frequencyUnit: blockout.unit,
					reason: blockout.reason,
					loading: false,
				});
			});
		}
		else 
		{
			this.setState({loading: false})
		}
	}

	submit = () =>
	{
		return new Promise((resolve) => 
		{
			const { 
				editing,
				startDateStr,
				endDateStr,
				startTimeStr,
				endTimeStr,
				untilDateStr,
				allDay,
				repeat,
				reason,
				frequency,
				frequencyUnit,
			} = this.state;
	
			var start, end, until;
			if (allDay)
			{
				start = startOfDay(parse(startDateStr, 'yyyy-MM-dd', new Date()));
				end = endOfDay(parse(endDateStr, 'yyyy-MM-dd', new Date()));
			}
			else 
			{
				start = parse(startDateStr + " " + startTimeStr, 'yyyy-MM-dd HH:mm', new Date());
				end = parse(endDateStr + " " + endTimeStr, 'yyyy-MM-dd HH:mm', new Date());
			}

			if (!isValid(start) || !isValid(end))
			{
				return this.setState({error: `Error: Invalid start or end date`});
			}

			if (repeat)
			{
				until = startOfDay(parse(untilDateStr, 'yyyy-MM-dd', new Date()));
			}

			const body = 
			{
				id: editing? this.state.id : null,
				start,
				end,
				until,
				allDay,
				repeat,
				reason,
				frequency: frequency,
				unit: frequencyUnit,
			};
	
			this.setState({loading: true}, async () => 
			{
				fetch(`${config.endpoint}/blockout`, {
					method: 'POST',
					headers: await api.getRequestHeaders(),
					body: JSON.stringify(body)
				}).then(async (res) => 
				{
					if (res.ok) 
					{
						ReactGA.event({ category: 'BlockoutPopup', action: editing?  `Update Blockout` : 'Create Blockout'});

						var data = await res.json();

						this.setState({
							loading: false,
							editing: true,
						}, () => {
							this.onSaveAndClose(data);
							resolve();
						});
					}
					else 
					{
						var text = await res.text();
						this.setState({error: `${res.status} ${res.statusText} - ${text}`, loading:false});
						resolve();
					}
				}).catch((err) => 
				{
					console.error(err);
					this.setState({error: err, loading: false});
					resolve();
				});
			});
		});
	}

	delete = () =>
	{
		this.setState({loading: true}, async() => 
		{
			fetch(`${config.endpoint}/blockout?id=${this.state.id}`, {
				method: 'DELETE',
				headers: await api.getRequestHeaders(),
			}).then(async (res) => 
			{
				if (res.ok) 
				{
					ReactGA.event({ category: 'BlockoutPopup', action: 'Delete Blockout'});

					this.setState({
						loading: false,
					}, () => this.onSaveAndClose({ id: this.state.id, delete: true }));
				}
				else {
					var text = await res.text();
					this.setState({error: `${res.status} ${res.statusText} - ${text}`, loading:false});
				}
			}).catch((err) => 
			{
				console.error(err);
				this.setState({error: err, loading: false})
			});
		});
	}

	onSaveAndClose = (blockout) => 
	{
		if (this.props.onSave) this.props.onSave(blockout);
		if (this.props.onClose) this.props.onClose();
	}

	render() 
	{
		const { 
			error,
			loading,
			editing,
			openDeleteDialog,
			startDateStr, 
			endDateStr,
			untilDateStr,
			allDay,
			startTimeStr,
			endTimeStr,
			repeat,
			frequency,
			frequencyUnit,
			reason
		} = this.state;

		const { 
			open, 
			onClose, 
		} = this.props;
		
		return (
			<SmoothModal
        open={open}
        size='small' 
        header={editing ? 'Update Blockout': 'Add Blockout'} 
        onClose={onClose}
      >
				<Modal.Content>
					{error && <Message error>{error}</Message>}
					<Form loading={loading}>

						<div style={{marginBottom: 20}}>
							{!loading && <Checkbox toggle label='All-day' defaultChecked={allDay} onChange={(e,d) => this.setState({allDay: d.checked})}/>}
						</div>
						
						<Form.Field>
							<h4>Starts At</h4>
							<input type='date' value={startDateStr} onChange={(e) => 
							{	
								if (new Date(e.target.value) > new Date(endDateStr)){
									this.setState({endDateStr: e.target.value})
								}
								this.setState({startDateStr: e.target.value})
							}}/>
							{!allDay &&
								<div style={{marginTop: 10}}>
									<input type='time' value={startTimeStr} onChange={(e) => this.setState({startTimeStr: e.target.value})}/>
								</div>
							}
						</Form.Field>

						<Form.Field>
							<h4>Ends At</h4>
							<input type='date' value={endDateStr} onChange={(e) => this.setState({endDateStr: e.target.value})}/>
							{!allDay && !loading &&
								<div style={{marginTop: 10}}>
									<input type='time' value={endTimeStr} onChange={(e) => this.setState({endTimeStr: e.target.value})}/>
								</div>
							}
						</Form.Field>

						<Form.Field style={{marginTop: 10}}>
							<h4>Repeat</h4>
							<div style={{marginBottom: 20}}>
								{!loading && <Checkbox label='Reoccuring' defaultChecked={repeat} onChange={(e,d) => this.setState({repeat: d.checked})}/>}
							</div>
							{repeat &&
								<div>
									<Input placeholder='Frequency' value={frequency} type='number' onChange={(e) => this.setState({frequency: e.target.valueAsNumber})}/>
									<Dropdown 
										fluid 
										style={{marginTop: 10}}
										placeholder="Unit"
										options={FREQUENCY_OPTIONS}
										selection
										value={frequencyUnit}
										onChange={(_, d) => this.setState({frequencyUnit: d.value})}
									/>
									<div style={{marginTop: 20}}>
										<h4>Repeat Until (Optional)</h4>
										<input type='date' value={untilDateStr} onChange={(e) => this.setState({untilDateStr: e.target.value})}/>
									</div>
								</div>
							}
						</Form.Field>

						<Form.Field>
							<h4>Reason</h4>
							<Input 
								placeholder='Reason (Optional)' 
								fluid 
								value={reason} 
								onChange={(e) => this.setState({reason: e.target.value})}
							/>
						</Form.Field>
						

					</Form>
				</Modal.Content>
				<Modal.Actions style={{borderWidth:0, backgroundColor: 'transparent'}}>
					{openDeleteDialog ? 
						<div>
							<h4>Are you sure you want to continue?</h4>
							<Button 
								disabled={loading} 
								onClick={() => this.setState({openDeleteDialog: false})}
							>Cancel
							</Button>
							<Button 
								disabled={loading} 
								onClick={() => this.setState({openDeleteDialog: false}, () => this.delete())}
								color='red'>Yes, Delete</Button>
						</div>
						:
						<div>
							<Button 
								fluid
								secondary
								style={{height: 50}}
								disabled={loading} 
								color={editing ? colors.update : colors.submit}
								onClick={this.submit}
							>
								{editing ? 'Update Blockout' : 'Create Blockout'}
							</Button>
							{editing && 
								<Button 
									fluid
									compact
									style={{marginTop: 10}}
									disabled={loading} 
									onClick={() => this.setState({openDeleteDialog: true})}
									>
									Delete
								</Button>}
						</div>
					}
				</Modal.Actions>
			</SmoothModal>
		)
	}
}