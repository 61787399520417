import { isMobile } from "react-device-detect";
import { Helmet } from "react-helmet";
import { Button, Grid, Loader, Segment } from "semantic-ui-react";
import colors from "../utils/colors";
import StatusBar from "./StatusBar";

const Page = (props) => 
{
	const { 
		header,
		helmet, 
		loading,
		loadingText,
		error,
		success,
		btnText,
		btnAction,
		btnDisabled,
		windowWidth,
		backgroundColor,
		padded,
		hideHeader,
	} = props;

	var { hasPermission, mobilePadding } = props;

	if (hasPermission === undefined)
	{	
		hasPermission = true;
	}

	if (mobilePadding === undefined) mobilePadding = 20;

	var allStyles ={ backgroundColor: '#fafafa'};
	if (backgroundColor) allStyles.backgroundColor = backgroundColor;

	var containerStyles = { };
	if (padded !== false) 
	{
		containerStyles.padding = 40;

		if (isMobile)
		{
			containerStyles.paddingRight = mobilePadding ? mobilePadding : 0;
			containerStyles.paddingLeft = mobilePadding ? mobilePadding : 0;
		}
	}

	// const [seconds, setSeconds] = useState(0);

	// useEffect(() => {
  //   let interval  = setInterval(() => {
  //       setSeconds(seconds => seconds + .1);
  //     }, 100);
  //   return () => clearInterval(interval);
  // }, [seconds]);

	return (
		<div style={allStyles}>

			{!hideHeader && 
				<div style={{height: '100%', backgroundColor: colors.primary}}>
				
					<div style={{padding: 15}}> 
						{windowWidth >= 600 
						? 
							<div style={{display: 'flex'}}>
								<div style={{fontSize: 25, color: colors.white, margin: 7, width: '100%'}}>{header ?? ''}</div>
								<div style={{display: 'flex', justifyContent: 'flex-end', width: '100%'}}>
									{btnText && <Button color='vk' onClick={btnAction} disabled={btnDisabled}>{btnText}</Button>}
								</div>
							</div>
						:
							<Grid stackable columns={1}>
								<Grid.Column>
									{btnText && <Button color='vk' style={{width: '100%'}} onClick={btnAction}>{btnText}</Button>}
								</Grid.Column>
						</Grid>
						}
					</div>
				</div>
			}
		
			<div className={loading ? 'loadingPage' : 'mypage'}> 

				<Helmet>
					<title>{helmet}</title>
				</Helmet>

				{loading && 
					<div style={{textAlign: 'center', marginTop: 100}}>
						<Loader active inline/>
						{loadingText && <p style={{marginTop: 20, width: '100%'}}>{loadingText}</p>}	
						{/* <div style={{marginTop: 20}}>{seconds.toLocaleString(undefined, { maximumFractionDigits: 1, minimumFractionDigits: 1})} seconds</div> */}
					</div>
				}

				<div style={containerStyles}>
					{!hasPermission && !loading && 
						<Segment>
							<div style={{margin:10}}>
								<h2 color={colors.accent}>Unauthorized</h2>
								<p style={{color: colors.darkGray}}>Please contact your local admin for elevated priviledges</p>
							</div>
						</Segment>
					}

					{hasPermission && !loading && (error || success) && <StatusBar style={{marginBottom: 20}} error={error} success={success} />}
					{/* main page contents */}
					{hasPermission && !loading && props.children}
				</div>
			</div>

		</div>
	)
}

export default Page;